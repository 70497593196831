import { useState } from 'react'
import { Link, Outlet } from 'react-router-dom'
import Logo from './Logo'
import './header.css'
import './responsiveHeader.css'
import '../pages/aboutCompany/aboutCompany.css'
import home from "../../assets/svg/home.svg";
import company from "../../assets/svg/company.svg";
import uslugi from "../../assets/svg/uslugi.svg";
import projects from "../../assets/svg/projects.svg";
import contacts from "../../assets/svg/contacts.svg";

const Header = ({ logoMenuOpen, logoMenuClose, isLoading }) => {
    const [burger_class, setBurgerClass] = useState('burger-bar unclicked')
    const [menu_class, setMenuClass] = useState('menu hidden')
    const [isMenuClicked, setIsMenuClicked] = useState(false)

    const currentUrl = window.location.pathname

    const updateMenu = () => {
        if (!isMenuClicked) {
            setBurgerClass('burger-bar clicked')
            setMenuClass('menu visible')
        }
        else {
            setBurgerClass('burger-bar unclicked')
            setMenuClass('menu hidden')
        }
        setIsMenuClicked(!isMenuClicked)
    }

    const closeMenu = () => {
        setIsMenuClicked(false)
        setBurgerClass('burger-bar unclicked')
        setMenuClass('menu hidden')
    }

    const menuVariantsIn = {
        initial: {
            x: '100vw',
            display: 'none',
        },
        animate: {
            x: 0,
            display: 'flex',
            transition: {
                duration: 0.3,
                ease: 'easeInOut'
            }
        }
    }
    const menuVariantsOut = {
        initial: {
            x: 0,
        },
        animate: {
            x: '100vw',
            display: 'none',
            transition: {
                duration: .3,
                ease: 'easeInOut'
            }
        }
    }

    const pVariantsIn = {
        initial: {
            opacity: 0,
            y: 150,
        },
        animate: i => ({
            opacity: 1,
            y: 0,
            transition: {
                duration: .2,
                delay: (i + 1) / 8,
            }
        })
    }

    const pVariantsOut = {
        initial: {
            opacity: 1,
            y: 0,
        },
        animate: {
            opacity: 0,
            y: 150,
            transition: {
                duration: 0.1,
            }
        },
    }

    const fadeIn = {
        initial: {
            opacity: 0,
        },
        animate: delay => ({
            opacity: 1,
            transition: {
                duration: 0.3,
                delay: delay
            }
        }),
    }
    const fadeOut = {
        initial: {
            opacity: 1,
        },
        animate: {
            opacity: 0,
            transition: {
                duration: 0.1,
            }
        },
    }
    return (
        <>
            <header
                style={{ display: isLoading ? 'none' : 'flex' }}
                //style={{ display:'none'}}
            >
                <div className='header__left'>
                    <Link to='/' onClick={closeMenu}>
                        <Logo
                            stroke={isMenuClicked ? logoMenuOpen : logoMenuClose}
                            cssClass='header__left__logo'
                        />
                    </Link>
                </div>

                <Link to='/' className="home-logo" data-info="направления"> <img src={home} alt='home' className='svg-icon' />
                </Link>
                <Link to='/company' className='company-logo' data-info="о компании"> <img src={company} alt='company' className='svg-icon' />
                </Link>
                <Link to='/uslugi' className='uslugi-logo' data-info="услуги">
                    <img src={uslugi} alt='uslugi' className='svg-icon' />
                </Link>
                <Link to='/objects' className='projects-logo' data-info="проекты">
                    <img src={projects} alt='projects' className='svg-icon' />
                </Link>

                <Link to='/contacts' className='contacts-logo' data-info="контакты">
                    <img src={contacts} alt='contacts' className='svg-icon' />
                </Link>





            </header >
            <div className="language" data-info="change language">
                <p className='text-lang'> ru en </p> </div>
            <Outlet />
        </>
    )
}

export default Header