
import '../../../components/mobileMenu/mobileMenu.css'
import React, {useEffect, useState} from 'react'
import { motion } from 'framer-motion'
import Carousel from 'nuka-carousel';
import useAppContext from '../../hooks/useAppContext'
import {
    imgAppear2,
    leftSideVariants,
    rightSideVariants1,

} from '../../animationVariants'

import './objectsPage.css'
import './responsiveObjectsPage.css'
import '../../../responsiveIndex.css'
import {Link} from "react-router-dom";
import heatIcon from "../../../assets/svg/icon_heat.svg";
import waterIcon from "../../../assets/svg/icon_water.svg";
import logoIcon from "../../../assets/svg/icon_logo.svg";
import windIcon from "../../../assets/svg/icon_wind.svg";
import coldIcon from "../../../assets/svg/icon_cold.svg";
import CompRealImg from '../../../assets/img/compreal-bg.jpg'
import {ScrollingCarousel} from "@trendyol-js/react-carousel";
import photoSvg from '../../../assets/svg/photo-svg.svg'


import Park from '../../../assets/img/Objects/Park.jpg'
import MMOImg1 from '../../../assets/img/Objects/okean1.jpg'
import Pharm from '../../../assets/img/Objects/Pharm.jpeg'
import Granit from '../../../assets/img/Objects/granit/1.jpg'

import LentaImg1 from '../../../assets/img/Objects/lenta1.jpg'
import PerekImg1 from '../../../assets/img/Objects/perek1.jpg'
import Chizhik1 from '../../../assets/img/Objects/chizhik1.jpeg'
import Utkonos1 from '../../../assets/img/Objects/utkonos/1.jpg'

import MaryinaImg1 from '../../../assets/img/Objects/maryino1.jpg'
import perekrestokSVG from '../../../assets/svg/perekrestok-svg.svg'
import chizhikSVG from '../../../assets/svg/chizhik-svg.svg'
import lentaSVG from '../../../assets/svg/lenta-svg.svg'
import SherbinkaImg1 from '../../../assets/img/Objects/Sherbinka-1.JPG'




const ObjectsPage = ({ menuColors }) => {
    const { setMenuColors } = useAppContext()
    useEffect(() => {
        setMenuColors(menuColors)
    }, [menuColors, setMenuColors])

    const [hystmodal, setHystmodal]=useState(false)
    const [hystmodal2, setHystmodal2]=useState(false)
    const [hystmodal3, setHystmodal3]=useState(false)

    return (
        <body>
        <script type="text/javascript">
            window.onbeforeunload = () {
            window.scrollTo(0, 0)
        }
        </script>
        <div className='section-container'>
            <div className={'background-header-objects'}
                 style={{
                     animation: `fade-in .10s ease-in-out`,
                     animationFillMode: 'backwards',

                 }}
            />
            <motion.div
                className='section-left-side-fullhd1'
                initial='initial'
                animate='animate'
                variants={leftSideVariants}
                style={{backgroundImage: `none`, display: 'none'}}
            >
            </motion.div>

            <motion.div
                className='section-left-side-mob'
                initial='initial'
                animate='animate'
                variants={leftSideVariants}>
                <p className='right-header3'> Реализованные объекты </p>
            </motion.div>

            <div className="hystmodal-menu" aria-hidden="true" style={{display: hystmodal3===true?"block":"none"}}>
                <div className="hystmodal-menu__wrap">
                    <div className="hystmodal-menu__window" role="dialog" aria-modal="true">
                        <div className="header-menu__modal">
                            <p className="h1-menu_main_modal"> </p>
                            <button onClick={()=>setHystmodal3(false)} className="hystmodal-menu__close">Х</button>
                        </div>
                        <div className="all-menu_text_modal">
                            <p> <Link to='/' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Главная </Link> </p>
                            <p> <Link to='/company' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> О компании </Link> </p>
                            <p> <Link to='/objects' style={{textDecoration: 'none', color: 'rgba(251, 169, 27, 1)'}}> Реализованные объекты </Link> </p>
                            <p> <Link to='/compreal' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Комплексная реализация </Link> </p>
                            <p>  <Link to='/service' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Сервисное обслуживание </Link> </p>
                            <p> <Link to='/engineering' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Проектирование объектов </Link> </p>
                            <p> <Link to='/proizvodstvo' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Производство оборудования </Link> </p>
                            <p> <Link to='/contacts' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Контакты </Link> </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hystmodal" aria-hidden="true" style={{display: hystmodal===true?"block":"none"}}>
                <div className="hystmodal__wrap">
                    <div className="hystmodal__window" role="dialog" aria-modal="true">
                        <div className="header__modal">
                            <p className="h1_main_modal"> Характеристики</p>
                            <button onClick={()=>setHystmodal(false)} className="hystmodal__close">Х</button>
                        </div>
                        <div className="all_text_modal">
                            <p>Адрес: Россия, Ленинградская область, п.Красный Бор</p>
                            <p>Заказчик: ООО "Лента"</p>
                            <p>Год постройки: 2020г.</p>
                            <p>Общая площадь: </p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>Ещё текст модального окна ...</p>
                            <p>КОНЕЦ МОДАЛЬНОГО ОКНА</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hystmodal-photo" aria-hidden="true" style={{display: hystmodal2===true?"block":"none"}}>
                <div className="hystmodal-photo__wrap">
                    <div className="hystmodal-photo__window" role="dialog" aria-modal="true">
                        <div className="header-photo__modal">
                            <p className="h1_main_modal-photo"> РЦ "Лента"</p>
                            <button onClick={()=>setHystmodal2(false)} className="hystmodal-photo__close">Х</button>
                        </div>
                        <div className="all_text_modal-photo">

                            <ScrollingCarousel className='cards1' show={1.1} slide={1} enableKeyboardControls={true} transition={0.5} swiping={true} infinite={false} rightArrow={false} leftArrow={false} swipeOn={1}>

                                <div className={'card-div-photo'} style={{backgroundColor: '#054146'}}>
                                    <div className={'card-img1'} style={{
                                        backgroundImage: `url(${LentaImg1})`}}>
                                    </div>
                                </div>

                                <div className={'card-div-photo'} style={{backgroundColor: '#054146'}}>
                                    <div className={'card-img1'} style={{
                                        backgroundImage: `url(${CompRealImg})`}}>
                                    </div>
                                </div>

                                <div className={'card-div-photo'} style={{backgroundColor: '#054146'}}>
                                    <div className={'card-img1'} style={{
                                        backgroundImage: `url(${CompRealImg})`}}>
                                    </div>
                                </div>
                            </ScrollingCarousel>
                        </div>
                    </div>
                </div>
            </div>





            <motion.div className='section-right-side1'
                initial='initial'
                animate='animate'
                variants={rightSideVariants1}>

                <Carousel className={'container2'} wrapAround={true} adaptiveHeight={true} slidesToShow={1} autoplay={true} autoplayInterval={3500} dragging={true} defaultControlsConfig={{
                    nextButtonText: '>',
                    prevButtonText: '<',
                    pagingDotsStyle: {
                        fill: '#eeeeee',
                        cellAlign: 'center',
                        animation: 'fade',
                    }
                }} >
                    <motion.div className={'div-photo'}
                        initial='initial'
                        animate='animate'
                        variants={imgAppear2} style={{
                        backgroundImage: `url(${Park})`}}> <Link to='/objects/cafe' className={'about-button2'}> Подробнее </Link> <p className={'realization'}> В РЕАЛИЗАЦИИ </p>
                        <p className={'name-div'}> НОВЫЕ <br/>ОБЪЕКТЫ </p>
                    </motion.div>

                    <motion.div className={'div-photo'}
                                initial='initial'
                                animate='animate'
                                variants={imgAppear2} style={{
                        backgroundImage: `url(${MMOImg1})`}}> <Link to='/objects/mmo' className={'about-button2'} style={{background: 'linear-gradient(100.79deg, #2B95E2 11%, #0FE5F2 57.7%)'}}> Подробнее </Link> <p className={'realization'}> В РЕАЛИЗАЦИИ </p>
                        <p className={'name-div2'}> МУЗЕЙ <br/> МИРОВОГО <br/> ОКЕАНА </p>
                    </motion.div>

                    <motion.div className={'div-photo'}
                                initial='initial'
                                animate='animate'
                                variants={imgAppear2} style={{
                        backgroundImage: `url(${Pharm})`}}> <Link to='/objects/pharm' className={'about-button2'} style={{background: 'conic-gradient(from 125.73deg at 3.41% 0%, #FFFFFF -78.2deg, #70A9FF 52.53deg, #FFFFFF 281.8deg, #70A9FF 412.53deg)'}}> Подробнее </Link> <p className={'realization'}> В РЕАЛИЗАЦИИ </p>
                        <p className={'name-div3'}> <br/> ФАРМСТАНДАРТ</p>
                    </motion.div>

                    <motion.div className={'div-photo'}
                                initial='initial'
                                animate='animate'
                                variants={imgAppear2} style={{
                        backgroundImage: `url(${Granit})`}}> <Link to='/objects/pharm' className={'about-button2'} style={{background: 'gray'}}> Подробнее </Link> <p className={'realization'}> В РЕАЛИЗАЦИИ </p>
                        <p className={'name-div3'}> <br/> ГРАНИТ</p>
                    </motion.div>

                </Carousel>

                <p className='name-carousel'> Складские комплексы </p>
                <ScrollingCarousel  className='cards1' show={1.1} slide={1} transition={0.5} swiping={true} infinite={false} rightArrow={false} leftArrow={false} swipeOn={1}>

                    <div className={'card-div1'}>
                        <div className={'card-div-header'} style={{backgroundColor: '#053D90'}}> <img src={lentaSVG} alt=""/></div>
                        <div className={'card-img1'} style={{
                            backgroundImage: `url(${LentaImg1})`}}>
                            <div onClick={()=>setHystmodal2(true)} className={'button-photo'}><img src={photoSvg} alt=""/> </div>
                            <div onClick={()=>setHystmodal(true)} className={'button'}> <p className={'about-button'}> подробнее </p> </div>
                        </div>

                    </div>

                    <div className={'card-div1'}>
                        <div className={'card-div-header'} style={{backgroundColor: '#FFDE00'}}> <img src={chizhikSVG} alt=""/></div>
                        <div className={'card-img1'} style={{
                            backgroundImage: `url(${Chizhik1})`}}>
                            <div className={'button'}> <p className={'about-button'}> подробнее </p> </div>
                        </div>
                    </div>

                    <div className={'card-div-perekrestok'} style={{backgroundColor: '#054146'}}>
                        <div className={'card-div-header'}>   <img src={perekrestokSVG} alt=""/></div>
                        <div className={'card-img1'} style={{
                            backgroundImage: `url(${PerekImg1})`}}>
                            <div className={'button'}> <p className={'about-button'}> подробнее </p> </div>
                        </div>
                    </div>
                </ScrollingCarousel>

                    <p className='name-carousel'> ГосОбъекты </p>
                <ScrollingCarousel className='cards1' show={1.1} slide={1} transition={0.5} swiping={true} infinite={false} rightArrow={false} leftArrow={false} swipeOn={1}>
                    <div className={'card-div1'}>
                        <div className={'card-div-header'} style={{backgroundColor: '#fba91b'}}> <p className={'card-text1'}> ЩЕРБИНКА</p></div>
                        <div className={'card-img1'} style={{
                            backgroundImage: `url(${SherbinkaImg1})`}}>
                            <div className={'button-photo'}><img src={photoSvg} alt=""/> </div>
                            <div onClick={()=>setHystmodal(true)} className={'button'}> <p className={'about-button'}> подробнее </p> </div>
                        </div>

                    </div>

                    <div className={'card-div1'}>
                        <div className={'card-div-header'} style={{backgroundColor: '#fba91b'}}> <p className={'card-text1'}> МАРЬИНА РОЩА</p> </div>
                        <div className={'card-img1'} style={{
                            backgroundImage: `url(${MaryinaImg1})`}}>
                            <div className={'button'}> <p className={'about-button'}> подробнее </p> </div>
                        </div>
                    </div>

                    <div className={'card-div1'}>
                        <div className={'card-div-header'} style={{backgroundColor: '#fba91b'}}><p className={'card-text1'}> МУЗЕЙ МИРОВОГО ОКЕАНА </p> </div>
                        <div className={'card-img1'} style={{
                            backgroundImage: `url(${MMOImg1})`}}>
                            <div className={'button'}> <p className={'about-button'}> подробнее </p> </div>
                        </div>
                    </div>
                </ScrollingCarousel>

                <div className={'objects-card'}>
                <div className={'div-objects1'}>
                    <p className={'name-objects1'}> Складские комплексы > </p>
                   <Link to='/objects/lenta' className={'div-objects-name'} style={{
                       backgroundImage: `url(${LentaImg1})`}} > <div className={'name-p-div'}> <p> ЛЕНТА </p> </div> </Link>
                    <Link to='/objects/lenta' className={'div-objects-name'} style={{
                        backgroundImage: `url(${PerekImg1})`}} > <div className={'name-p-div'}> <p> ПЕРЕКРЁСТОК</p> </div> </Link>
                    <Link to='/objects/utkonos' className={'div-objects-name'} style={{
                        backgroundImage: `url(${Utkonos1})`}} > <div className={'name-p-div'}> <p> УТКОНОС </p> </div> </Link>
                    <Link to='/objects/lenta' className={'div-objects-name'} style={{
                        backgroundImage: `url(${MaryinaImg1})`}} > <div className={'name-p-div'}> <p> ЯРМАРКА <br/> МАРЬИНА <br/> РОЩА </p> </div> </Link>
                </div>
                <div className={'div-objects2'}>
                    <p className={'name-objects2'}> ГосОбъекты > </p>
                    <Link to='/objects/mmo' className={'div-objects-name'} style={{
                        backgroundImage: `url(${MMOImg1})`}} > <div className={'name-p-div'}> <p> МУЗЕЙ <br/> МИРОВОГО <br/> ОКЕАНА </p> </div> </Link>
                    <Link to='/objects/lenta' className={'div-objects-name'} style={{
                        backgroundImage: `url(${SherbinkaImg1})`}} > <div className={'name-p-div'}> <p> ЯРМАРКА <br/> ЩЕРБИНКА </p> </div> </Link>
                    <Link to='/objects/lenta' className={'div-objects-name'} style={{
                        backgroundImage: `url(${MaryinaImg1})`}} > <div className={'name-p-div'}> <p> ЯРМАРКА <br/> МАРЬИНА <br/> РОЩА </p> </div> </Link>
                    <Link to='/objects/lenta' className={'div-objects-name'} style={{
                        backgroundImage: `url(${MaryinaImg1})`}} > <div className={'name-p-div'}> <p> ЯРМАРКА <br/> МАРЬИНА <br/> РОЩА </p> </div> </Link>
                </div>
                </div>


            </motion.div>

            <box className='footer'>
                <div className='mobile-menu'>
                    <Link to='/hot' className='mobile-menu-link'>
                        <img src={heatIcon} alt='heatIcon' className='svg-icon' />
                    </Link>
                    <Link to='/water' className='mobile-menu-link'>
                        <img src={waterIcon} alt='waterIcon' className='svg-icon' />
                    </Link>
                    <div onClick={()=>setHystmodal3(true)} className={'logo-icon-menu'}> <img src={logoIcon} alt="logoIcon"/> </div>
                    <Link to='/wind' className='mobile-menu-link'>
                        <img src={windIcon} alt='windIcon' className='svg-icon' />
                    </Link>
                    <Link to='/cold' className='mobile-menu-link'>
                        <img src={coldIcon} alt='coldIcon' className='svg-icon' />
                    </Link>
                </div>
            </box>
        </div>

        </body>
    )
}

export default ObjectsPage