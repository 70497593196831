import { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

const Arrow = forwardRef(({ stroke }, ref) => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)

    return (
        <svg
            ref={ref} width="74"
            height="46" viewBox="0 0 74 46"
            fill="none" xmlns="http://www.w3.org/2000/svg"
            className='arrow-back'
            onClick={goBack}
        >
            <path d="M58 17C59.1046 17 60 16.1046 60 15C60 13.8954 59.1046 13 58 13V17ZM0.585786 13.5858C-0.195262 14.3668 -0.195262 15.6332 0.585786 16.4142L13.3137 29.1421C14.0948 29.9232 15.3611 29.9232 16.1421 29.1421C16.9232 28.3611 16.9232 27.0948 16.1421 26.3137L4.82843 15L16.1421 3.68629C16.9232 2.90524 16.9232 1.63891 16.1421 0.857864C15.3611 0.0768158 14.0948 0.0768158 13.3137 0.857864L0.585786 13.5858ZM58 13L2 13V17L58 17V13Z" fill={`${stroke}`} />
        </svg>
    )
})

export const MArrow = motion(Arrow)