import Img from "../../../assets/img/404-2.png";
const Page404 = () => {
    return <div
            style={{
                height: '100%',
                width: '100%',
                position: 'absolute',

            }}>
        <div
            style={{
                height: '78%',
                width: '60%',
                margin: 'auto',
                backgroundImage: `url(${Img})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',

            }}>

        </div>
            </div>

}

export default Page404