
import '../../../components/mobileMenu/mobileMenu.css'
import React, {useEffect, useState} from 'react'
import { motion } from 'framer-motion'
import useAppContext from '../../hooks/useAppContext'
import {
    leftSideVariants,
    rightSideVariants, rightSideVariants1,

} from '../../animationVariants'
import './hotPage.css'
import './responsiveHotPage.css'
import {ScrollingCarousel} from "@trendyol-js/react-carousel";
import photoSvg from '../../../assets/svg/photo-svg.svg'

import {Link} from "react-router-dom";
import hotIcon from "../../../assets/svg/heat.svg";
import waterIcon from "../../../assets/svg/icon_water.svg";
import logoIcon from "../../../assets/svg/icon_logo.svg";
import windIcon from "../../../assets/svg/icon_wind.svg";
import coldIcon from "../../../assets/svg/icon_cold.svg";
import zavesi from "../../../assets/img/hot/zavesi.png";
import vulkan from "../../../assets/img/hot/vulkan.png";
import MMOImg1 from "../../../assets/img/Objects/okean1.jpg";
import SherbinkaImg1 from "../../../assets/img/Objects/Sherbinka-1.JPG";
import MaryinaImg1 from "../../../assets/img/Objects/maryino1.jpg";


const HotPage = ({ menuColors }) => {
    const { setMenuColors } = useAppContext()
    useEffect(() => {
        setMenuColors(menuColors)
    }, [])

    const [hystmodal, setHystmodal]=useState(false)
    const [hystmodal2, setHystmodal2]=useState(false)
    const [hystmodal3, setHystmodal3]=useState(false)

    return (
        <body>
        <script type="text/javascript">
            window.onbeforeunload = function () {
            window.scrollTo(0, 0)
        }
        </script>
        <div className='section-container'>

            <motion.div
                className='section-left-side-mob'
                initial='initial'
                animate='animate'
                variants={leftSideVariants}
                style={{backgroundColor: '#EE423E'}}

            >
                <p className='right-header3' style={{backgroundColor: '#EE423E', color: 'white'}} > Теплоснабжение </p>
            </motion.div>

            <div className="hystmodal-menu" aria-hidden="true" style={{display: hystmodal3===true?"block":"none"}}>
                <div className="hystmodal-menu__wrap">
                    <div className="hystmodal-menu__window" role="dialog" aria-modal="true">
                        <div className="header-menu__modal">
                            <p className="h1-menu_main_modal"> </p>
                            <button onClick={()=>setHystmodal3(false)} className="hystmodal-menu__close">Х</button>
                        </div>
                        <div className="all-menu_text_modal">
                            <p> <Link to='/' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Главная </Link> </p>
                            <p> <Link to='/company' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> О компании </Link> </p>
                            <p> <Link to='/objects' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Реализованные объекты </Link> </p>
                            <p> <Link to='/compreal' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Комплексная реализация </Link> </p>
                            <p> <Link to='/service' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Сервисное обслуживание </Link> </p>
                            <p> <Link to='/engineering' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Проектирование объектов </Link> </p>
                            <p> <Link to='/proizvodstvo' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Производство оборудования </Link> </p>
                            <p> <Link to='/contacts' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Контакты </Link> </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hystmodal" aria-hidden="true" style={{display: hystmodal===true?"block":"none"}}>
                <div className="hystmodal__wrap">
                    <div className="hystmodal__window" role="dialog" aria-modal="true">
                        <div className="header__modal">
                            <p className="h1_main_modal" style={{color: '#EE423E'}} > Отопление складских помещений</p>
                            <button onClick={()=>setHystmodal(false)} className="hystmodal__close">Х</button>
                        </div>
                        <div className="all_text_modal">
                            <p style={{fontWeight: 'bolder', textTransform: 'uppercase'}}>Воздушно-тепловые завесы</p>
                            <p style={{lineHeight: '3vh'}}>Использование воздушно-тепловых завес актуально на складских помещениях, где часто открываются двери и ворота, а также когда необходимо разграничить температурные зоны (например, холодильные и морозильные склады от помещений с комнатной температурой).
                                Реализация системы отопления складов и цехов с использованием водяных тепловентиляторов — достаточно популярное и эффективное решение. </p>

                        </div>
                    </div>
                </div>
            </div>


            <div className={'background-header'}
                 style={{
                     animation: `fade-in .10s ease-in-out`,
                     animationFillMode: 'backwards',
                     background: 'linear-gradient(180deg, #EE423E 0%, #D9D2C4 100%)',
                     boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25) inset'

                 }}
            />

            <motion.div
                className='section-right-side'
                initial='initial'
                animate='animate'
                variants={rightSideVariants1}>

                <p className='top-header' style={{color: '#EE423E'}}> ТЕПЛОСНАБЖЕНИЕ </p>
                <p className='name-proizvodstvo-carousel'> Отопление складских помещений </p>
                <ScrollingCarousel className='cards-hot'>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Воздушно-тепловые завесы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${zavesi})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'}> <p className={'about-hot-button'}> подробнее </p> </div> </div>
                    </div>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Водяные тепловентиляторы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${vulkan})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'} > <p className={'about-hot-button'}> подробнее </p> </div> </div>
                    </div>


                </ScrollingCarousel>

                <p className='name-proizvodstvo-carousel'> Конвекторное отопление </p>
                <ScrollingCarousel className='cards-hot'>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Воздушно-тепловые завесы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${zavesi})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'}> <p className={'about-hot-button'}> подробнее </p> </div> </div>
                    </div>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Водяные тепловентиляторы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${vulkan})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'} > <p className={'about-hot-button'}> подробнее </p> </div> </div>
                    </div>

                </ScrollingCarousel>

                <p className='name-proizvodstvo-carousel'> Конвекторное отопление </p>
                <ScrollingCarousel className='cards-hot'>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Воздушно-тепловые завесы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${zavesi})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'}> <p className={'about-hot-button'}> подробнее </p> </div> </div>
                    </div>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Водяные тепловентиляторы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${vulkan})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'} > <p className={'about-hot-button'}> подробнее </p> </div> </div>
                    </div>

                </ScrollingCarousel>
                <div className={'right-text-all'}>
                <a href='#hot1' className={'right-text5'}> Агрегаты воздушного отопления и тепловые завесы </a>
                    <br/>
                    <br/> <a href='#hot2' className={'right-text5'}> Конвекторное отопление </a><br/>
                    <br/><a href='#hot3' className={'right-text5'}> Обогрев приточного воздуха </a><br/>
                    <br/><a href='#hot4' className={'right-text5'}> Нагрев горячего водоснабжения за счет теплоносителя </a><br/>
                    <br/><a href='#hot5' className={'right-text5'}> Тепловые насосы в качестве источника тепловой энергии </a> </div>

                <div>
                    <p className={'section-title'} id='hot1'> Агрегаты воздушного отопления и тепловые завесы </p>

                <div className={'text-photo'}>
                    <p className={'text-left'}> Агрегаты воздушного отопления и тепловые завесы используются для поддержания комфортной температуры в помещении.
                        Агрегаты воздушного отопления обычно устанавливаются в центральной системе отопления и используются для нагрева воздуха, который затем циркулирует по всему зданию.
                        Тепловые завесы устанавливаются у входных дверей и окон, чтобы предотвратить проникновение холодного воздуха в помещение.
                        Они работают путем создания потока горячего воздуха, который образует барьер между внешней и внутренней средой.
                        Это помогает снизить потери тепла и сохранить комфортную температуру в помещении. </p>
                    <p className={'photo-right'}> </p>
                </div>
                </div>

                <div>
                    <p className={'section-title'} id='hot2' style={{marginTop: '5vh'}}> Конвекторное отопление</p>

                    <div className={'text-photo2'} style={{marginTop: '0%'}}>
                        <p className={'text-right'}> Конвекторное отопление - это система отопления, которая работает на основе конвекции. Конвекция - это процесс передачи тепла путем движения воздуха. Конвекторы являются устройствами, которые нагревают воздух, который затем поднимается и циркулирует по помещению, создавая комфортную температуру.

                            Конвекторы могут быть электрическими или работать на газе или других видов топлива. Электрические конвекторы обычно устанавливаются на стену и подключаются к электрической сети. Газовые конвекторы, с другой стороны, подключаются к газовой линии и обычно устанавливаются на стену или на пол. </p>
                        <p className={'photo-left'}> </p>
                    </div>
                </div>

                <div>
                    <p className={'section-title'} id='hot3' style={{marginTop: '7vh'}}> Обогрев приточного воздуха
                    </p>

                    <div className={'text-photo'}>
                        <p className={'text-left'}> Обогрев приточного воздуха - это процесс нагрева свежего воздуха, который поступает в помещение через вентиляционную систему. Это может быть достигнуто с помощью различных методов, включая электрические или газовые нагреватели, тепловые насосы или теплообменники.
                            Преимущества обогрева приточного воздуха включают улучшение качества воздуха в помещении, поскольку свежий воздух поступает в помещение, а также повышение эффективности системы отопления и кондиционирования воздуха. Обогрев приточного воздуха также может снизить затраты на отопление, поскольку он позволяет использовать более низкие температуры нагрева, чем при обогреве воздуха в помещении. </p>
                        <p className={'photo-right'}> </p>
                    </div>
                </div>

                <div>
                    <p className={'section-title'} id='hot4' style={{marginTop: '7vh'}}> Нагрев горячего водоснабжения за счет теплоносителя </p>

                    <div className={'text-photo2'} style={{marginTop: '0%'}}>
                        <p className={'text-right'}> Конвекторное отопление - это система отопления, которая работает на основе конвекции. Конвекция - это процесс передачи тепла путем движения воздуха. Конвекторы являются устройствами, которые нагревают воздух, который затем поднимается и циркулирует по помещению, создавая комфортную температуру.

                            Конвекторы могут быть электрическими или работать на газе или других видов топлива. Электрические конвекторы обычно устанавливаются на стену и подключаются к электрической сети. Газовые конвекторы, с другой стороны, подключаются к газовой линии и обычно устанавливаются на стену или на пол. </p>
                        <p className={'photo-left'}> </p>
                    </div>
                </div>

                <div>
                    <p className={'section-title'} id='hot5' style={{marginTop: '7vh'}}> Тепловые насосы в качестве источника тепловой энергии
                    </p>

                    <div className={'text-photo'}>
                        <p className={'text-left'}> Обогрев приточного воздуха - это процесс нагрева свежего воздуха, который поступает в помещение через вентиляционную систему. Это может быть достигнуто с помощью различных методов, включая электрические или газовые нагреватели, тепловые насосы или теплообменники.
                            Преимущества обогрева приточного воздуха включают улучшение качества воздуха в помещении, поскольку свежий воздух поступает в помещение, а также повышение эффективности системы отопления и кондиционирования воздуха. Обогрев приточного воздуха также может снизить затраты на отопление, поскольку он позволяет использовать более низкие температуры нагрева, чем при обогреве воздуха в помещении. </p>
                        <p className={'photo-right'}> </p>
                    </div>

                    <p className={'name-popular'}> Популярные объекты > </p>
                    <div className={'div-objects-card3'}>
                        <Link to='/objects/mmo' className={'div-objects-popular'} style={{
                            backgroundImage: `url(${MMOImg1})`}} > <div className={'name-p-div'}> <p> МУЗЕЙ <br/> МИРОВОГО <br/> ОКЕАНА </p> </div> </Link>
                        <Link to='/objects/lenta' className={'div-objects-popular'} style={{
                            backgroundImage: `url(${SherbinkaImg1})`}} > <div className={'name-p-div'}> <p> ЯРМАРКА <br/> ЩЕРБИНКА </p> </div> </Link>
                        <Link to='/objects/lenta' className={'div-objects-popular'} style={{
                            backgroundImage: `url(${MaryinaImg1})`}} > <div className={'name-p-div'}> <p> ЯРМАРКА <br/> МАРЬИНА <br/> РОЩА </p> </div> </Link>
                    </div>

                    <div className={'empty'}> </div>
                </div>


            </motion.div>

            <box className='footer'>
                <div className='mobile-menu'>
                    <Link to='/hot' className='mobile-menu-link'>
                        <img src={hotIcon} alt='heatIcon' className='svg-icon' style={{boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}  />
                    </Link>
                    <Link to='/water' className='mobile-menu-link'>
                        <img src={waterIcon} alt='waterIcon' className='svg-icon'  />
                    </Link>
                    <div onClick={()=>setHystmodal3(true)} className={'logo-icon-menu'}> <img src={logoIcon} alt="logoIcon"/> </div>
                    <Link to='/wind' className='mobile-menu-link'>
                        <img src={windIcon} alt='windIcon' className='svg-icon' />
                    </Link>
                    <Link to='/cold' className='mobile-menu-link'>
                        <img src={coldIcon} alt='coldIcon' className='svg-icon' />
                    </Link>

                </div>
            </box>
        </div>

        </body>
    )
}

export default HotPage