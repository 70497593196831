
import '../../../components/mobileMenu/mobileMenu.css'
import Carousel from 'nuka-carousel';
import {useEffect, useState} from 'react'
import { motion } from 'framer-motion'
import useAppContext from '../../hooks/useAppContext'
import {
    leftSideVariants,
    rightSideVariants1,

} from '../../animationVariants'
import './StyleObjects.css'

import SherbinkaImg1 from '../../../assets/img/Objects/Sherbinka-1.JPG'
import LentaImg1 from '../../../assets/img/Objects/lenta1.jpg'
import MMOImg1 from '../../../assets/img/Objects/okean1.jpg'
import MaryinaImg1 from '../../../assets/img/Objects/maryino1.jpg'
import {Link} from "react-router-dom";
import React from 'react';
import Park from "../../../assets/img/Objects/Park.jpg";


const LentaPage = ({ menuColors }) => {
    const { setMenuColors } = useAppContext()
    useEffect(() => {
        setMenuColors(menuColors)
    }, [])

    return (

        <div className='section-container' style={{display: 'flex'}}>
            <motion.div
                className='section-left-side-fullhd2'
                initial='initial'
                animate='animate'
                variants={leftSideVariants}>

                <p  className={'rout'}> <Link to='/objects#references' className={'rout_link'}>  Реализованные объекты > </Link> <span style={{color: `white`}}> / Складские комплексы </span> </p>

                <Carousel className='slider' wrapAround={true} adaptiveHeight={true} slidesToShow={1} autoplay={true} autoplayInterval={4700} dragging={true} defaultControlsConfig={{
                    nextButtonText: '>',
                    prevButtonText: '<',
                    pagingDotsStyle: {
                            fill: '#ffffff',
                            cellAlign: 'center',
                            animation: 'zoom'
                    }
                }} >
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${Park})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MMOImg1})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MaryinaImg1})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${SherbinkaImg1})`}} />
                </Carousel>

                <p className='stroke-4'> Распределительный центр “Лента” находится в Ленинградской области посёлок Красный Бор. Данный объект реализован под хранение продовольственного товара. </p>
            </motion.div>

            <motion.div
                className='section-right-side2'
                initial='initial'
                animate='animate'
                variants={rightSideVariants1}>
                <div className={'section-top-img'}/>

                <p className='stroke-1'> Распредилительный центр ЛЕНТА </p>
                <p className='stroke-3'> Общая площадь здания: <span className='text_about'> 61 967 м2</span> </p>
                <p className='stroke-3'> Площадь административно-бытовых комплексов составляет: <span className='text_about'> 3 349 м2</span> </p>
                <p className='stroke-3'> Площадь климатических камер +10С...+25С составляет: <span className='text_about'> 8 669 м2</span> </p>
                <p className='stroke-3'> Площадь среднетемпературных холодильных камер -5С...+10С: <span className='text_about'> 9 046 м2</span> </p>
                <p className='stroke-3'> Площадь низкотемпературных холодильных камер -10С...-24С: <span className='text_about'> 3 597 м2</span> </p>
                <p className='stroke-3'> Площадь кровли для реализации системы внутреннего водостока: <span className='text_about'> 58 700 м2</span> </p>
                <p className='stroke-3'> Общий расход приточной вентиляции: <span className='text_about'> 62 280 м3/ч </span> </p>
                <p className='stroke-3'> Общая тепловая мощность здания (отопление, вентиляция, ГВС): <span className='text_about'> 3 004 кВт</span> </p>
                <p className='stroke-3'> Общий расход воды для ХВС и ГВС: <span className='text_about'> 178 м3 в сутки</span> </p>
                <p className='stroke-3'> Общая холодопроизводительность климатичкских и холодильных систем составляет: <span className='text_about'> 2 456 кВт.</span> </p>
            </motion.div>

        </div>

    )
}
export default LentaPage