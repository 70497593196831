import { useState, createContext } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import {
    LoadingPage,
    MainPage,
    WaterPage,
    WindPage,
    Page404,
    ContactsPage,
} from './components/pages'
import Header from './components/header/Header'
import ElementArticlePage from './components/elementArticlePage/ElementArticlePage'
import AboutCompany from './components/pages/aboutCompany/AboutCompany'
import HotPage from './components/pages/hotPage/HotPage'
import UslugiPage from './components/pages/uslugiPage/UslugiPage'
import ColdPage from './components/pages/coldPage/ColdPage'
import ObjectsPage from "./components/pages/objectsPage/ObjectsPage";
import LentaPage from "./components/pages/objectsPage/LentaPage";
import MMOPage from "./components/pages/objectsPage/MMOPage";
import CafePage from "./components/pages/objectsPage/CafePage";
import PharmPage from "./components/pages/objectsPage/PharmPage";
import UtkonosPage from "./components/pages/objectsPage/UtkonosPage";

import {heatPageData, waterPageData, windPageData, coldPageData, productionPageData} from './components/data'


export const AppContext = createContext()

function App() {
    const [isLoading, setIsLoading] = useState(false)
    const [globalMenuColors, setMenuColors] = useState({
        logoMenuClose: '#FBA91B',
        logoMenuOpen: '#FFF',
        menuColor: '#FBA91B',
    })
    const [screenWidth, setScreenWidth] = useState(document.documentElement.clientWidth)

    function debounce(callback, delay) {
        let timer
        return function (...args) {
            clearTimeout(timer)
            timer = setTimeout(() => {
                callback.apply(this, args)
            }, delay)
        }
    }

    const watchWidth = debounce(() => setScreenWidth(document.documentElement.clientWidth), 300)

    window.addEventListener('resize', watchWidth)

    const context = { globalMenuColors, setMenuColors, screenWidth, setScreenWidth }

    const data = [
        {
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/GoldenGateBridge-001.jpg/1200px-GoldenGateBridge-001.jpg",
            caption: `<div>
                  San Francisco
                  <br/>
                  Next line
                </div>`
        },
        {
            image: "https://cdn.britannica.com/s:800x450,c:crop/35/204435-138-2F2B745A/Time-lapse-hyper-lapse-Isle-Skye-Scotland.jpg",
            caption: "Scotland"
        },
        {
            image: "https://static2.tripoto.com/media/filter/tst/img/735873/TripDocument/1537686560_1537686557954.jpg",
            caption: "Darjeeling"
        },
        {
            image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Palace_of_Fine_Arts_%2816794p%29.jpg/1200px-Palace_of_Fine_Arts_%2816794p%29.jpg",
            caption: "San Francisco"
        },
        {
            image: "https://i.natgeofe.com/n/f7732389-a045-402c-bf39-cb4eda39e786/scotland_travel_4x3.jpg",
            caption: "Scotland"
        },
        {
            image: "https://www.tusktravel.com/blog/wp-content/uploads/2020/07/Best-Time-to-Visit-Darjeeling-for-Honeymoon.jpg",
            caption: "Darjeeling"
        },
        {
            image: "https://www.omm.com/~/media/images/site/locations/san_francisco_780x520px.ashx",
            caption: "San Francisco"
        },
        {
            image: "https://images.ctfassets.net/bth3mlrehms2/6Ypj2Qd3m3jQk6ygmpsNAM/61d2f8cb9f939beed918971b9bc59bcd/Scotland.jpg?w=750&h=422&fl=progressive&q=50&fm=jpg",
            caption: "Scotland"
        },
        {
            image: "https://www.oyorooms.com/travel-guide/wp-content/uploads/2019/02/summer-7.jpg",
            caption: "Darjeeling"
        }
    ];

    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }




    return (
        <AppContext.Provider value={context}>
            <BrowserRouter>
                <AnimatePresence>
                    <Routes>
                        <Route
                            path='/'
                            element={<Header
                                {...globalMenuColors}
                                isLoading={isLoading}
                            />}>
                            <Route index element={
                                isLoading ?
                                    <LoadingPage setIsLoading={setIsLoading} />
                                    : <MainPage setMenuColors={setMenuColors} />
                            } />

                            <Route
                                path='/hot'
                                element={<HotPage
                                    menuColors={{
                                        logoMenuClose: '#FFF',
                                        logoMenuOpen: '#FFF',
                                        menuColor: '#FFF',}}
                                />}
                            />

                            <Route
                                path='/hot/:id'
                                element={<ElementArticlePage
                                    menuColors={{
                                        logoMenuClose: '#FFF',
                                        logoMenuOpen: '#FFF',
                                        menuColor: '#FFF',
                                    }}
                                    {...heatPageData}
                                />}
                            />

                            <Route
                                path='/cold/:id'
                                element={<ElementArticlePage
                                    menuColors={{
                                        logoMenuClose: '#FFF',
                                        logoMenuOpen: '#FFF',
                                        menuColor: '#FFF',
                                    }}
                                    {...coldPageData}
                                />}
                            />

                            <Route
                                path='/wind/:id'
                                element={<ElementArticlePage
                                    menuColors={{
                                        logoMenuClose: '#FFF',
                                        logoMenuOpen: '#FFF',
                                        menuColor: '#FFF',
                                    }}
                                    {...windPageData}
                                />}
                            />

                            <Route
                                path='/water/:id'
                                element={<ElementArticlePage
                                    menuColors={{
                                        logoMenuClose: '#FFF',
                                        logoMenuOpen: '#FFF',
                                        menuColor: '#FFF',
                                    }}
                                    {...waterPageData}
                                />}
                            />



                            <Route
                                path='/wind'
                                element={<WindPage
                                    menuColors={{
                                        logoMenuClose: '#FFF',
                                        logoMenuOpen: '#FFF',
                                        menuColor: '#FFF',}}
                                />}
                            />

                            <Route
                                path='/water'
                                element={<WaterPage
                                    menuColors={{
                                        logoMenuClose: '#FFF',
                                        logoMenuOpen: '#FFF',
                                        menuColor: '#FFF',}}
                                />}
                            />

                            <Route
                                path='/cold'
                                element={<ColdPage
                                    menuColors={{
                                        logoMenuClose: '#FFF',
                                        logoMenuOpen: '#FFF',
                                        menuColor: '#FFF',}}
                                />}
                            />


                            <Route
                                path='/company'
                                element={<AboutCompany
                                    menuColors={{
                                        logoMenuClose: '#FFF',
                                        logoMenuOpen: 'rgba(37,40,42,0)',
                                        menuColor: '#FFF',
                                    }}
                                />}
                            />

                            <Route
                                path='/objects'
                                element={<ObjectsPage
                                    menuColors={{
                                        logoMenuClose: '#fdfdfd',
                                        logoMenuOpen: 'rgba(37,40,42,0)',
                                        menuColor: '#ffffff',
                                    }}
                                />}
                            />

                            <Route
                                path='/uslugi'
                                element={<UslugiPage
                                    menuColors={{
                                        logoMenuClose: '#25262a',
                                        logoMenuOpen: 'rgba(37,40,42,0)',
                                        menuColor: '#FFF',
                                    }}
                                />}
                            />

                            <Route
                                path='/contacts'
                                element={<ContactsPage
                                    menuColors={{
                                        logoMenuClose: '#25262a',
                                        logoMenuOpen: 'rgba(37,40,42,0)',
                                        menuColor: '#FFF',
                                    }}
                                />}
                            />
                            <Route
                                path='/objects/lenta'
                                element={<LentaPage
                                    menuColors={{
                                        logoMenuClose: '#fdfdfd',
                                        logoMenuOpen: 'rgba(37,40,42,0)',
                                        menuColor: '#FFF',
                                    }}
                                />}
                            />

                            <Route
                                path='/objects/mmo'
                                element={<MMOPage
                                    menuColors={{
                                        logoMenuClose: '#fdfdfd',
                                        logoMenuOpen: 'rgba(37,40,42,0)',
                                        menuColor: '#FFF',
                                    }}
                                />}
                            />

                            <Route
                                path='/objects/cafe'
                                element={<CafePage
                                    menuColors={{
                                        logoMenuClose: '#fdfdfd',
                                        logoMenuOpen: 'rgba(37,40,42,0)',
                                        menuColor: '#FFF',
                                    }}
                                />}
                            />

                            <Route
                                path='/objects/pharm'
                                element={<PharmPage
                                    menuColors={{
                                        logoMenuClose: '#fdfdfd',
                                        logoMenuOpen: 'rgba(37,40,42,0)',
                                        menuColor: '#FFF',
                                    }}
                                />}
                            />

                            <Route
                                path='/objects/utkonos'
                                element={<UtkonosPage
                                    menuColors={{
                                        logoMenuClose: '#fdfdfd',
                                        logoMenuOpen: 'rgba(37,40,42,0)',
                                        menuColor: '#FFF',
                                    }}
                                />}
                            />

                            <Route path='*' element={<Page404 />} />
                        </Route>
                    </Routes>
                </AnimatePresence>
            </BrowserRouter>
        </AppContext.Provider>
    )
}

export default App