
import '../../../components/mobileMenu/mobileMenu.css'
import Carousel from 'nuka-carousel';
import {useEffect, useState} from 'react'
import { motion } from 'framer-motion'
import useAppContext from '../../hooks/useAppContext'
import {
    leftSideVariants,
    rightSideVariants1,

} from '../../animationVariants'
import './StyleObjects.css'
import {Link} from "react-router-dom";
import React from 'react';

import MMO1 from '../../../assets/img/Objects/okean/1.png'
import MMO2 from '../../../assets/img/Objects/okean/2.png'
import MMO3 from '../../../assets/img/Objects/okean/3.png'
import MMO4 from '../../../assets/img/Objects/okean/4.png'
import MMO5 from '../../../assets/img/Objects/okean/5.png'
import MMO6 from '../../../assets/img/Objects/okean/6.png'
import MMO7 from '../../../assets/img/Objects/okean/7.png'
import MMO8 from '../../../assets/img/Objects/okean/8.png'
import MMO9 from '../../../assets/img/Objects/okean/9.png'
import MMO10 from '../../../assets/img/Objects/okean/10.png'



const MMOPage = ({ menuColors }) => {
    const { setMenuColors } = useAppContext()
    useEffect(() => {
        setMenuColors(menuColors)
    }, [])

    return (

        <div className='section-container' style={{display: 'flex'}}>
            <motion.div
                className='section-left-side-fullhd2'
                initial='initial'
                animate='animate'
                variants={leftSideVariants}>

                <p  className={'rout'}> <Link to='/objects' className={'rout_link'}>  В РЕАЛИЗАЦИИ > </Link> <span style={{color: `white`}}> / ГосОбъекты </span> </p>

                <Carousel className='slider' wrapAround={true}  adaptiveHeight={true} slidesToShow={1} autoplay={true} autoplayInterval={4700} dragging={true} defaultControlsConfig={{
                    nextButtonText: '>',
                    prevButtonText: '<',
                    pagingDotsStyle: {
                            fill: '#ffffff',
                            cellAlign: 'center',
                            animation: 'zoom'
                    }
                }} >
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MMO1})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MMO2})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MMO3})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MMO4})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MMO5})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MMO6})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MMO7})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MMO8})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MMO9})`}} />
                    <div className='div-photo2' style={{
                        backgroundImage: `url(${MMO10})`}} />
                </Carousel>

                <p className='stroke-4'> Музей мирового океана - общественное здание с культурно-просвятительским, выставочным назначением, находящийся в г. Калининград. </p>
            </motion.div>

            <motion.div
                className='section-right-side2'
                initial='initial'
                animate='animate'
                variants={rightSideVariants1}>
                <div className={'section-top-img'}/>

                <p className='stroke-1'> МУЗЕЙ МИРОВОГО ОКЕАНА </p>
                <p className='stroke-3'> Общая площадь здания: <span className='text_about'> 9 973 м2 </span> </p>
                <p className='stroke-3'> Общая холодильная мощность системы кондиционирования помещений и охлаждение воздуха системы вентиляции: <span className='text_about'> 985 кВт </span> </p>
                <p className='stroke-3'> Общий расход приточной вентиляции: <span className='text_about'> 37 250 м3/ч </span> </p>
                <p className='stroke-3'> Общая тепловая мощность здания (отопление, вентиляция, ГВС): <span className='text_about'> 1 765 кВт </span> </p>
                <p className='stroke-3'> Общий расход воды для ХВС и ГВС: <span className='text_about'> 86 м3 в сутки </span> </p>
            </motion.div>

        </div>

    )
}
export default MMOPage