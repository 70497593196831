
import '../../../components/mobileMenu/mobileMenu.css'
import {useEffect, useState} from 'react'
import { motion } from 'framer-motion'
import useAppContext from '../../hooks/useAppContext'
import {
    leftSideVariants,
    rightSideVariants,

} from '../../animationVariants'
import './windPage.css'
import './responsiveWindPage.css'
import '../../../responsiveIndex.css'
import {ScrollingCarousel} from "@trendyol-js/react-carousel";

import {Link} from "react-router-dom";
import hotIcon from "../../../assets/svg/icon_heat.svg";
import waterIcon from "../../../assets/svg/icon_water.svg";
import logoIcon from "../../../assets/svg/icon_logo.svg";
import windIcon from "../../../assets/svg/wind.svg";
import coldIcon from "../../../assets/svg/icon_cold.svg";
import zavesi from "../../../assets/img/hot/zavesi.png";
import vulkan from "../../../assets/img/hot/vulkan.png";


const WindPage = ({ menuColors }) => {
    const { setMenuColors } = useAppContext()
    useEffect(() => {
        setMenuColors(menuColors)
    }, [])

    const [hystmodal, setHystmodal]=useState(false)
    const [hystmodal2, setHystmodal2]=useState(false)
    const [hystmodal3, setHystmodal3]=useState(false)

    return (
        <body>
        <div className='section-container'>
            <motion.div
                className='section-left-side-fullhd'
                initial='initial'
                animate='animate'
                variants={leftSideVariants}
                style={{backgroundColor: '#fba91b',
                        }}
            >
                <div>
                    <video className='coldVideo' />

                </div>

                <div>
                    <p className='quote'>“Правда - воздух, без которого дышать нельзя...“</p>
                    <p className='quoteAutor'>— Иван Тургенев</p>
                </div>

            </motion.div>

            <motion.div
                className='section-left-side-mob'
                initial='initial'
                animate='animate'
                variants={leftSideVariants}
                style={{backgroundColor: '#fba91b'}}

            >
                <p className='right-header3' style={{backgroundColor: '#fba91b', color: 'black'}} > Воздухоснабжение </p>
            </motion.div>

            <div className="hystmodal-menu" aria-hidden="true" style={{display: hystmodal3===true?"block":"none"}}>
                <div className="hystmodal-menu__wrap">
                    <div className="hystmodal-menu__window" role="dialog" aria-modal="true">
                        <div className="header-menu__modal">
                            <p className="h1-menu_main_modal"> </p>
                            <button onClick={()=>setHystmodal3(false)} className="hystmodal-menu__close">Х</button>
                        </div>
                        <div className="all-menu_text_modal">
                            <p> <Link to='/' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Главная </Link> </p>
                            <p> <Link to='/company' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> О компании </Link> </p>
                            <p> <Link to='/objects' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Реализованные объекты </Link> </p>
                            <p> <Link to='/compreal' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Комплексная реализация </Link> </p>
                            <p>  <Link to='/service' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Сервисное обслуживание </Link> </p>
                            <p> <Link to='/engineering' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Проектирование объектов </Link> </p>
                            <p> <Link to='/proizvodstvo' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Производство оборудования </Link> </p>
                            <p> <Link to='/contacts' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Контакты </Link> </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hystmodal" aria-hidden="true" style={{display: hystmodal===true?"block":"none"}}>
                <div className="hystmodal__wrap">
                    <div className="hystmodal__window" role="dialog" aria-modal="true">
                        <div className="header__modal">
                            <p className="h1_main_modal" style={{color: '#fba91b'}} > Отопление складских помещений</p>
                            <button onClick={()=>setHystmodal(false)} className="hystmodal__close">Х</button>
                        </div>
                        <div className="all_text_modal">
                            <p style={{fontWeight: 'bolder', textTransform: 'uppercase'}}>Воздушно-тепловые завесы</p>
                            <p style={{lineHeight: '3vh'}}>Использование воздушно-тепловых завес актуально на складских помещениях, где часто открываются двери и ворота, а также когда необходимо разграничить температурные зоны (например, холодильные и морозильные склады от помещений с комнатной температурой).
                                Реализация системы отопления складов и цехов с использованием водяных тепловентиляторов — достаточно популярное и эффективное решение. </p>

                        </div>
                    </div>
                </div>
            </div>


            <motion.div
                className='section-right-side'
                initial='initial'
                animate='animate'
                variants={rightSideVariants}>
                <div className={'section-top-img'}/>
                <p className='right-header' style={{color: '#fba91b'}}> ВОЗДУХОСНАБЖЕНИЕ </p>

                <p className='name-proizvodstvo-carousel'> Отопление складских помещений </p>
                <ScrollingCarousel className='cards-hot'>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Воздушно-тепловые завесы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${zavesi})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'}> <p className={'about-wind-button'}> подробнее </p> </div> </div>
                    </div>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Водяные тепловентиляторы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${vulkan})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'} > <p className={'about-wind-button'}> подробнее </p> </div> </div>
                    </div>


                </ScrollingCarousel>

                <p className='name-proizvodstvo-carousel'> Конвекторное отопление </p>
                <ScrollingCarousel className='cards-hot'>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Воздушно-тепловые завесы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${zavesi})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'}> <p className={'about-wind-button'}> подробнее </p> </div> </div>
                    </div>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Водяные тепловентиляторы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${vulkan})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'} > <p className={'about-wind-button'}> подробнее </p> </div> </div>
                    </div>

                </ScrollingCarousel>

                <p className='name-proizvodstvo-carousel'> Конвекторное отопление </p>
                <ScrollingCarousel className='cards-hot'>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Воздушно-тепловые завесы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${zavesi})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'}> <p className={'about-wind-button'}> подробнее </p> </div> </div>
                    </div>

                    <div className={'card-proizvodstvo-div1'}>
                        <div className={'card-proizvodstvo-div-header'} style={{backgroundColor: '#4C4C4F'}}> <p className={'card-text77'}  > Водяные тепловентиляторы </p></div>
                        <div className={'card-proizvodstvo-img1'} style={{
                            backgroundImage: `url(${vulkan})`}}>
                            <div onClick={()=>setHystmodal(true)} className={'button'} > <p className={'about-wind-button'}> подробнее </p> </div> </div>
                    </div>

                </ScrollingCarousel>
                <div className={'right-text-all'}>
                <a href='/wind/1' className={'right-text5'}> Воздухоснабжение складских помещений </a>
                    <br/>
                    <br/> <a href='/wind/2' className={'right-text5'}> Воздухоснабжение офисов </a><br/>
                    <br/><a href='/wind/3' className={'right-text5'}> Воздухоснабжение для пищевых и промышленных производств </a><br/>
                    <br/><a href='/wind/4' className={'right-text5'}> Фрикулинг вентиляцией для складских холодильных камер </a><br/>
                    <br/><a href='/wind/5' className={'right-text5'}> Система сжатого воздуха </a> </div>






            </motion.div>

            <box className='footer'>
                <div className='mobile-menu'>
                    <Link to='/hot' className='mobile-menu-link'>
                        <img src={hotIcon} alt='heatIcon' className='svg-icon'  />
                    </Link>
                    <Link to='/water' className='mobile-menu-link'>
                        <img src={waterIcon} alt='waterIcon' className='svg-icon'  />
                    </Link>
                    <div onClick={()=>setHystmodal3(true)} className={'logo-icon-menu'}> <img src={logoIcon} alt="logoIcon"/> </div>
                    <Link to='/wind' className='mobile-menu-link'>
                        <img src={windIcon} alt='windIcon' className='svg-icon' style={{boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'}}  />
                    </Link>
                    <Link to='/cold' className='mobile-menu-link'>
                        <img src={coldIcon} alt='coldIcon' className='svg-icon' />
                    </Link>
                </div>
            </box>
        </div>

        </body>
    )
}

export default WindPage