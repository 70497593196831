const Logo = ({ stroke, cssClass }) => (

            <svg xmlns="http://www.w3.org/2000/svg" width="2.3vw" height="100" viewBox="0 0 26 55" fill="none">
                    <path d="M16.9299 39.1049C16.7997 39.2351 16.6975 39.3884 16.6208 39.5604C16.5465 39.7346 16.5093 39.9205 16.5093 40.1181L16.5093 42.7461L22.6205 42.7461L22.6205 41.7283L17.4457 41.7283L17.4457 40.1529C17.4457 40.0274 17.4899 39.9205 17.5758 39.8322C17.6618 39.7463 17.7687 39.7021 17.8965 39.7021L22.6205 39.7021L22.6205 38.6844L17.9313 38.6844C17.7338 38.6844 17.5503 38.7215 17.3806 38.7959C17.211 38.8703 17.06 38.9725 16.9299 39.1049Z" fill="black"/>
                    <path d="M16.9439 34.0655C16.8138 34.2003 16.7092 34.356 16.6302 34.5326C16.5512 34.7092 16.5117 34.8974 16.5117 35.0949L16.5117 36.3148C16.5117 36.5123 16.5512 36.7006 16.6302 36.8771C16.7092 37.0537 16.8138 37.2094 16.9439 37.3396C17.074 37.4697 17.2274 37.5719 17.4063 37.6463C17.5829 37.7206 17.7711 37.7578 17.9686 37.7578L21.2008 37.7578C21.3983 37.7578 21.5866 37.7206 21.7632 37.6463C21.9397 37.5719 22.0954 37.4697 22.2256 37.3396C22.3557 37.2094 22.4579 37.0561 22.5346 36.8771C22.609 36.7006 22.6461 36.5123 22.6461 36.3148L22.6461 34.6813L24.0426 34.6813C24.1681 34.6813 24.275 34.7255 24.3633 34.8114C24.4493 34.8974 24.4934 35.0043 24.4934 35.1321L24.4934 36.2103C24.4934 36.352 24.4446 36.4635 24.3447 36.5425C24.2448 36.6216 24.1333 36.6611 24.0078 36.6611C23.9683 36.6611 23.9265 36.6587 23.8823 36.6541C23.8382 36.6494 23.7963 36.6471 23.7568 36.6471L23.7568 37.6765C23.9846 37.6765 24.203 37.6463 24.4075 37.5882C24.6119 37.5301 24.7932 37.4395 24.9465 37.321C25.0999 37.2025 25.2254 37.0537 25.3206 36.8702C25.4159 36.6889 25.4624 36.4752 25.4624 36.2312L25.4624 35.0949C25.4624 34.8974 25.4229 34.7092 25.3439 34.5326C25.2649 34.356 25.1603 34.2003 25.0302 34.0655C24.9001 33.9308 24.7467 33.8262 24.5678 33.7518C24.3912 33.6775 24.203 33.6403 24.0055 33.6403L17.9663 33.6403C17.7688 33.6403 17.5806 33.6775 17.404 33.7518C17.2274 33.8262 17.074 33.9308 16.9439 34.0655ZM21.6865 34.6813L21.6865 36.2684C21.6865 36.3938 21.6423 36.5007 21.5563 36.589C21.4704 36.675 21.3635 36.7191 21.2357 36.7191L17.9315 36.7191C17.806 36.7191 17.6968 36.675 17.6062 36.589C17.5155 36.503 17.4691 36.3962 17.4691 36.2684L17.4691 35.1321C17.4691 35.0066 17.5155 34.8997 17.6062 34.8114C17.6968 34.7255 17.806 34.6813 17.9315 34.6813L21.6865 34.6813Z" fill="black"/>
                    <path d="M17.4458 32.8008L17.4458 32.4917L22.6206 32.4917L22.6206 31.4507L16.5117 31.4507L16.5117 32.8008L17.4458 32.8008Z" fill="black"/>
                    <path d="M16.9299 26.8608C16.7997 26.9909 16.6975 27.1443 16.6208 27.3162C16.5465 27.4905 16.5093 27.6764 16.5093 27.8739L16.5093 30.502L22.6205 30.502L22.6205 29.4842L17.4457 29.4842L17.4457 27.9088C17.4457 27.7833 17.4899 27.6764 17.5758 27.5881C17.6618 27.5021 17.7687 27.458 17.8965 27.458L22.6205 27.458L22.6205 26.4402L17.9313 26.4402C17.7338 26.4402 17.5503 26.4774 17.3806 26.5518C17.211 26.6284 17.06 26.7307 16.9299 26.8608Z" fill="black"/>
                    <path d="M16.9833 16.8315C16.8416 16.9547 16.7277 17.1034 16.6394 17.2823C16.5534 17.4589 16.5093 17.6588 16.5093 17.8795L16.5093 19.0878C16.5093 19.3178 16.5534 19.5177 16.6394 19.6919C16.7254 19.8662 16.8439 20.0149 16.9879 20.1427C17.1343 20.2682 17.304 20.3635 17.4968 20.4262C17.6897 20.489 17.8942 20.5215 18.1056 20.5215L21.1961 20.5215C21.3936 20.5215 21.5771 20.4843 21.7468 20.41C21.9164 20.3356 22.0674 20.2334 22.1976 20.1032C22.3277 19.9731 22.4299 19.8221 22.5043 19.6478C22.5786 19.4735 22.6158 19.2876 22.6158 19.0901L22.6158 17.8818C22.6158 17.6913 22.581 17.5124 22.5089 17.3427C22.4369 17.1731 22.3393 17.0244 22.2138 16.8989C22.0883 16.7734 21.942 16.6689 21.7747 16.5922C21.6097 16.5132 21.4308 16.469 21.2425 16.4621L20.9474 16.4621L20.9474 17.445C21.1217 17.445 21.2867 17.4682 21.4447 17.517C21.6027 17.5635 21.6817 17.6866 21.6817 17.8841L21.6817 19.0576C21.6817 19.1831 21.6352 19.29 21.54 19.3783C21.4447 19.4642 21.3425 19.5084 21.2332 19.5084L20.2503 19.5084L20.2503 16.4644L18.0824 16.4644C17.8686 16.4644 17.6688 16.4969 17.4782 16.5597C17.2923 16.6154 17.1274 16.7084 16.9833 16.8315ZM19.3418 17.4752L19.3418 19.4991L17.8965 19.4991C17.778 19.4991 17.6757 19.4549 17.5898 19.362C17.5038 19.2714 17.4597 19.1668 17.4597 19.0483L17.4597 17.9237C17.4597 17.8051 17.5038 17.7006 17.5898 17.61C17.6757 17.5193 17.7803 17.4729 17.8965 17.4729L19.3418 17.4729L19.3418 17.4752Z" fill="black"/>
                    <path d="M16.9833 21.7319C16.8416 21.8551 16.7277 22.0038 16.6394 22.1827C16.5534 22.3593 16.5093 22.5591 16.5093 22.7799L16.5093 23.9882C16.5093 24.2182 16.5534 24.4181 16.6394 24.5923C16.7254 24.7666 16.8439 24.9153 16.9879 25.0431C17.1343 25.1686 17.304 25.2639 17.4968 25.3266C17.6897 25.3893 17.8942 25.4219 18.1056 25.4219L21.1961 25.4219C21.3936 25.4219 21.5771 25.3847 21.7468 25.3103C21.9164 25.236 22.0674 25.1337 22.1976 25.0036C22.3277 24.8735 22.4299 24.7225 22.5043 24.5482C22.5786 24.3739 22.6158 24.188 22.6158 23.9905L22.6158 22.7822C22.6158 22.5917 22.581 22.4128 22.5089 22.2431C22.4369 22.0735 22.3393 21.9248 22.2138 21.7993C22.0883 21.6738 21.942 21.5693 21.7747 21.4926C21.6097 21.4136 21.4308 21.3694 21.2425 21.3625L20.9474 21.3625L20.9474 22.3454C21.1217 22.3454 21.2867 22.3686 21.4447 22.4174C21.6027 22.4639 21.6817 22.587 21.6817 22.7845L21.6817 23.958C21.6817 24.0835 21.6352 24.1903 21.54 24.2786C21.4447 24.3646 21.3425 24.4088 21.2332 24.4088L20.2503 24.4088L20.2503 21.3648L18.0824 21.3648C17.8686 21.3648 17.6688 21.3973 17.4782 21.4601C17.2923 21.5158 17.1274 21.6088 16.9833 21.7319ZM19.3418 22.3779L19.3418 24.4018L17.8965 24.4018C17.778 24.4018 17.6757 24.3577 17.5898 24.2647C17.5038 24.1741 17.4597 24.0695 17.4597 23.951L17.4597 22.8264C17.4597 22.7079 17.5038 22.6033 17.5898 22.5127C17.6757 22.4221 17.7803 22.3756 17.8965 22.3756L19.3418 22.3756L19.3418 22.3779Z" fill="black"/>
                    <path d="M17.2925 12.2888C17.1345 12.3678 16.9974 12.4701 16.8835 12.5979C16.7696 12.7233 16.679 12.8697 16.6116 13.037C16.5442 13.202 16.5117 13.3763 16.5117 13.5575L16.5117 15.5117L22.6345 15.5117L22.6345 14.494L17.4946 14.494L17.4946 13.5111C17.4946 13.4019 17.5295 13.3066 17.5945 13.2276C17.6619 13.1486 17.7456 13.1021 17.8501 13.0858C17.8734 13.0789 17.9291 13.0742 18.0151 13.0742C18.1011 13.0742 18.1569 13.0742 18.1801 13.0742L18.1801 12.1262C18.1406 12.1262 18.0755 12.1262 17.9849 12.1262C17.8943 12.1262 17.8292 12.1331 17.7897 12.1494C17.6154 12.1633 17.4505 12.2098 17.2925 12.2888Z" fill="black"/>
                    <path d="M17.4458 11.4004L17.4458 11.0913L22.6206 11.0913L22.6206 10.0504L16.5117 10.0504L16.5117 11.4004L17.4458 11.4004Z" fill="black"/>
                    <path d="M16.9299 5.46236C16.7997 5.59249 16.6975 5.74585 16.6208 5.91779C16.5465 6.09207 16.5093 6.27797 16.5093 6.47548L16.5093 9.10352L22.6205 9.10352L22.6205 8.08576L17.4457 8.08576L17.4457 6.51033C17.4457 6.38485 17.4899 6.27796 17.5758 6.18966C17.6618 6.10369 17.7687 6.05954 17.8965 6.05954L22.6205 6.05954L22.6205 5.04178L17.9313 5.04178C17.7338 5.04178 17.5503 5.07896 17.3806 5.15332C17.211 5.23 17.06 5.33224 16.9299 5.46236Z" fill="black"/>
                    <path d="M17.4039 0.111227C17.2273 0.185584 17.0716 0.29015 16.9415 0.424921C16.8114 0.559692 16.7068 0.715379 16.6278 0.891976C16.5488 1.06857 16.5093 1.25678 16.5093 1.45429L16.5093 2.67421C16.5093 2.87172 16.5488 3.05993 16.6278 3.23653C16.7068 3.41313 16.8114 3.56881 16.9415 3.69893C17.0716 3.82906 17.225 3.9313 17.4039 4.00566C17.5805 4.08001 17.7687 4.11719 17.9662 4.11719L21.1984 4.11719C21.3959 4.11719 21.5841 4.08001 21.7607 4.00566C21.9373 3.9313 22.093 3.82906 22.2231 3.69893C22.3532 3.56881 22.4555 3.41545 22.5322 3.23653C22.6065 3.05993 22.6437 2.87172 22.6437 2.67421L22.6437 1.04068L24.0402 1.04068C24.1657 1.04068 24.2726 1.08483 24.3609 1.17081C24.4468 1.25678 24.491 1.36367 24.491 1.49147L24.491 2.56964C24.491 2.71138 24.4422 2.82292 24.3423 2.90193C24.2424 2.98093 24.1308 3.02043 24.0054 3.02043C23.9659 3.02043 23.924 3.0181 23.8799 3.01346C23.8357 3.00881 23.7939 3.00649 23.7544 3.00649L23.7544 4.03587C23.9821 4.03587 24.2005 4.00566 24.405 3.94757C24.6095 3.88715 24.7907 3.79885 24.9441 3.68035C25.0975 3.56184 25.2229 3.41313 25.3182 3.22956C25.4135 3.04832 25.46 2.83454 25.46 2.59056L25.46 1.45429C25.46 1.25678 25.4205 1.06857 25.3414 0.891975C25.2624 0.715378 25.1579 0.559692 25.0278 0.424921C24.8976 0.290149 24.7443 0.185584 24.5654 0.111227C24.3888 0.0368706 24.2005 -0.00031028 24.003 -0.000310272L17.9639 -0.000310008C17.7687 -0.00263363 17.5828 0.0345473 17.4039 0.111227ZM21.6864 1.04068L21.6864 2.62773C21.6864 2.75321 21.6422 2.8601 21.5562 2.94839C21.4703 3.03437 21.3634 3.07852 21.2356 3.07852L17.9313 3.07852C17.8059 3.07852 17.6967 3.03437 17.606 2.94839C17.5154 2.86242 17.4689 2.75553 17.4689 2.62773L17.4689 1.49147C17.4689 1.366 17.5154 1.25911 17.606 1.17081C17.6967 1.08483 17.8059 1.04069 17.9313 1.04069L21.6864 1.04068Z" fill="black"/>
                    <path d="M21.4869 49.906C21.7448 49.6481 21.9516 49.3391 22.1003 48.9859C22.249 48.635 22.3234 48.2586 22.3234 47.8659L22.3234 44.0295L20.8525 44.0295L20.8525 47.766C20.8525 48.1192 20.6875 48.5049 20.4319 48.7489C20.2228 48.9464 19.9672 49.1602 19.5675 49.1602L16.2075 49.1602L16.2075 46.4647C16.2075 46.3276 16.103 46.2138 15.9798 46.2138L14.6275 46.2138L14.6275 49.1602L6.05322 49.1602L6.05322 44.2503C6.05322 44.1271 5.95098 44.0295 5.8255 44.0295L4.47314 44.0295L4.47314 50.5195C4.47314 50.6403 4.57538 50.7402 4.69389 50.7402L19.4467 50.7402C19.8394 50.7402 20.2135 50.6659 20.5667 50.5172C20.9199 50.3708 21.2289 50.164 21.4869 49.906Z" fill="black"/>
                    <path d="M5.52787 38.5012C5.38148 38.6522 5.2653 38.8265 5.177 39.0217C5.09102 39.2192 5.04687 39.4306 5.04687 39.6607L5.04687 41.0734C5.04687 41.3012 5.09102 41.5196 5.177 41.7241C5.26297 41.9309 5.3838 42.1075 5.53484 42.2585C5.68588 42.4095 5.8648 42.528 6.06928 42.614C6.27608 42.7 6.49218 42.7441 6.7199 42.7441L13.7443 42.7441C13.972 42.7441 14.1904 42.7 14.3949 42.614C14.6017 42.528 14.7783 42.4072 14.9293 42.2585C15.0804 42.1075 15.1989 41.9285 15.2849 41.7241C15.3708 41.5173 15.415 41.3012 15.415 41.0734L15.415 39.6607C15.415 39.4329 15.3708 39.2192 15.2849 39.0217C15.1989 38.8242 15.0804 38.6522 14.934 38.5012C14.7876 38.3501 14.6156 38.2293 14.4205 38.1387C14.2229 38.0481 14.0161 37.9969 13.7954 37.9876L13.0542 37.9876L13.0542 39.1681L13.8372 39.1681C13.965 39.1866 14.0742 39.2424 14.1672 39.34C14.2578 39.4353 14.3043 39.5491 14.3043 39.6769L14.3043 41.0363C14.3043 41.1827 14.2532 41.3035 14.1532 41.3988C14.0533 41.494 13.9302 41.5428 13.7838 41.5428L6.67807 41.5428C6.53168 41.5428 6.40853 41.494 6.30861 41.3988C6.2087 41.3035 6.15758 41.1827 6.15758 41.0363L6.15758 39.6769C6.15758 39.5491 6.20405 39.4376 6.29467 39.34C6.38529 39.2447 6.49683 39.1866 6.62463 39.1681L7.4077 39.1681L7.4077 37.9876L6.66645 37.9876C6.44803 37.9969 6.2389 38.0481 6.04139 38.1387C5.84388 38.2293 5.67426 38.3501 5.52787 38.5012Z" fill="black"/>
                    <path d="M6.9822 33.3686C7.62353 33.6381 8.26253 33.9053 8.90385 34.1702C8.26485 34.4444 7.62353 34.7163 6.9822 34.9858C6.3432 35.2553 5.70187 35.5226 5.06055 35.7875L5.06055 36.9121L15.4193 36.9121L15.4193 35.7038L8.17655 35.7038C8.57854 35.5295 8.97589 35.3599 9.37091 35.1973C9.7636 35.0323 10.1656 34.8627 10.5792 34.6907L10.5792 34.1284L10.5792 33.6474C10.1679 33.4824 9.76592 33.3151 9.37091 33.1478C8.97821 32.9782 8.58087 32.8155 8.17655 32.6598L15.4193 32.6598L15.4193 31.4399L5.06055 31.4399L5.06055 32.5506C5.70187 32.8271 6.34088 33.099 6.9822 33.3686Z" fill="black"/>
                    <path d="M5.52787 26.0715C5.38148 26.2225 5.2653 26.3968 5.177 26.592C5.09102 26.7895 5.04687 27.0009 5.04687 27.231L5.04687 28.6438C5.04687 28.8715 5.09102 29.0899 5.177 29.2944C5.26297 29.5012 5.3838 29.6778 5.53484 29.8288C5.68588 29.9798 5.8648 30.0984 6.06928 30.1843C6.27608 30.2703 6.49218 30.3145 6.7199 30.3145L13.7443 30.3145C13.972 30.3145 14.1904 30.2703 14.3949 30.1843C14.6017 30.0984 14.7783 29.9775 14.9293 29.8288C15.0804 29.6778 15.1989 29.4989 15.2849 29.2944C15.3708 29.0876 15.415 28.8715 15.415 28.6438L15.415 27.231C15.415 27.0033 15.3708 26.7895 15.2849 26.592C15.1989 26.3945 15.0804 26.2225 14.934 26.0715C14.7876 25.9204 14.6156 25.7996 14.4205 25.709C14.2229 25.6184 14.0161 25.5672 13.7954 25.558L13.0542 25.558L13.0542 26.7384L13.8372 26.7384C13.965 26.757 14.0742 26.8127 14.1672 26.9103C14.2578 27.0056 14.3043 27.1194 14.3043 27.2472L14.3043 28.6066C14.3043 28.753 14.2532 28.8738 14.1532 28.9691C14.0533 29.0643 13.9302 29.1131 13.7838 29.1131L6.67807 29.1131C6.53168 29.1131 6.40853 29.0643 6.30861 28.9691C6.2087 28.8738 6.15758 28.753 6.15758 28.6066L6.15758 27.2472C6.15758 27.1194 6.20405 27.0079 6.29467 26.9103C6.38529 26.815 6.49683 26.757 6.62463 26.7384L7.4077 26.7384L7.4077 25.558L6.66645 25.558C6.44803 25.5672 6.2389 25.6184 6.04139 25.709C5.84388 25.7996 5.67426 25.9204 5.52787 26.0715Z" fill="black"/>
                    <path d="M1.84497 46.0191C2.07037 45.7821 2.30273 45.659 2.60248 45.6148L3.21825 45.6125L3.22289 44.0324L2.64431 44.0324C2.24464 44.0324 2.03551 44.0766 1.7102 44.2276C1.37095 44.3856 1.07352 44.5947 0.824894 44.8526C0.57394 45.1106 0.371782 45.4126 0.220745 45.7519C0.0743555 46.0842 -3.73776e-07 46.449 -3.56814e-07 46.837L-1.25439e-07 52.1303C-1.08375e-07 52.5207 0.0743563 52.8971 0.22307 53.248C0.371783 53.6012 0.578588 53.9102 0.834189 54.1658C1.09211 54.4237 1.40116 54.6282 1.75203 54.7769C2.1029 54.9256 2.477 55 2.8697 55L16.3956 55C16.7883 55 17.1648 54.9256 17.5133 54.7769C17.8642 54.6282 18.1732 54.4237 18.4311 54.1658C18.6891 53.9102 18.8935 53.6012 19.0423 53.248C19.191 52.8971 19.2653 52.5207 19.2653 52.1303L19.2653 52.0002L17.7945 52.0304C17.755 52.4393 17.6225 52.7321 17.3855 52.9575C17.1345 53.1969 16.858 53.3084 16.5118 53.3084L2.7907 53.313C2.43518 53.313 2.12846 53.1922 1.87983 52.9575C1.62655 52.7159 1.50107 52.4115 1.50107 52.0513L1.50107 46.8626C1.50107 46.5419 1.61726 46.2585 1.84497 46.0191Z" fill="black"/>
                    <path d="M19.038 44.138L17.688 44.138L17.688 47.9023L19.038 47.9023C19.1612 47.9023 19.2657 47.8001 19.2657 47.6816L19.2657 44.3843C19.2657 44.2403 19.1612 44.138 19.038 44.138Z" fill="black"/>
    </svg>
)

export default Logo