import React, {useEffect, useState} from 'react'
import { motion } from 'framer-motion'
import useAppContext from '../../hooks/useAppContext'
import {
    leftSideVariants,
    rightSideVariants1,

} from '../../animationVariants'
import './aboutCompany.css'
import './responsiveAboutCompany.css'
import '../../../components/mobileMenu/mobileMenu.css'
import companyImg from '../../../assets/img/company-bg.jpg'
import {Link} from "react-router-dom";
import heatIcon from "../../../assets/svg/icon_heat.svg";
import waterIcon from "../../../assets/svg/icon_water.svg";
import logoIcon from "../../../assets/svg/icon_logo.svg";
import windIcon from "../../../assets/svg/icon_wind.svg";
import coldIcon from "../../../assets/svg/icon_cold.svg";
import EngineeringImg from "../../../assets/img/engineering-bg.jpg";

const AboutCompany = ({ menuColors }) => {
    const { setMenuColors } = useAppContext()
    useEffect(() => {
        setMenuColors(menuColors)
    }, [])

    const [hystmodal3, setHystmodal3]=useState(false)

    return (
        <div className='section-company'> <p className='section-company2' style={{ backgroundImage: `url(${companyImg})` }}>  </p>


            <motion.div
                className='section-left-side-mob'
                initial='initial'
                animate='animate'
                variants={leftSideVariants}
                style={{
                    textAlign: "center",
                    margin: "auto"
                }}
            >
                <p className='right-header3'> О компании </p>
            </motion.div>


            <div className="hystmodal-menu" aria-hidden="true" style={{display: hystmodal3===true?"block":"none"}}>
                <div className="hystmodal-menu__wrap">
                    <div className="hystmodal-menu__window" role="dialog" aria-modal="true">
                        <div className="header-menu__modal">
                            <p className="h1-menu_main_modal"> </p>
                            <button onClick={()=>setHystmodal3(false)} className="hystmodal-menu__close">X</button>
                        </div>
                        <div className="all-menu_text_modal">
                            <p> <Link to='/' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Главная </Link> </p>
                            <p> <Link to='/company' style={{textDecoration: 'none', color: 'rgba(251, 169, 27, 1)'}}> О компании </Link> </p>
                            <p> <Link to='/objects' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Реализованные объекты </Link> </p>
                            <p> <Link to='/compreal' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Комплексная реализация </Link> </p>
                            <p> <Link to='/service' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Сервисное обслуживание </Link> </p>
                            <p> <Link to='/engineering' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Проектирование объектов </Link> </p>
                            <p> <Link to='/proizvodstvo' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Производство оборудования </Link> </p>
                            <p> <Link to='/contacts' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Контакты </Link> </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className='right-header-company'
                 > <p className='header-company'> </p> </div>

            <motion.div
                className='section-company'
                initial='initial'
                animate='animate'
                variants={rightSideVariants1}>
                <div className='top-header' style={{color: '#fba91b'}}> О КОМПАНИИ </div>
                <div className={'left-card'}>
                <p className='name-left'> О КОМПАНИИ
                    <br/> <br/>  СМС-Инжиниринг ориентирована на комплексную реализацию проект «под ключ». Мы помогаем
                    заказчику найти оптимальное соотношение между
                    капитальными и операционными расходами на
                    инженерные системы. Наша команда стремится построить долгосрочные, надежные и открытые отношения с заказчиком.
                </p>

                <div className={'right-card'}>
                    <p className='name-right'> МИССИЯ КОМПАНИИ
                        <br/> <br/> Предложить лучшее и подходящее решения для достижения поставленных целей заказчика. Основные акценты при реализации объектов: стремление к высокому качеству, планирование и выполнение в поставленные сроки.
                    </p>
                </div>

                </div>
            </motion.div>

            <box className='footer'>
                <div className='mobile-menu'>
                    <Link to='/hot' className='mobile-menu-link'>
                        <img src={heatIcon} alt='heatIcon' className='svg-icon' />
                    </Link>
                    <Link to='/water2' className='mobile-menu-link'>
                        <img src={waterIcon} alt='waterIcon' className='svg-icon' />
                    </Link>
                    <div onClick={()=>setHystmodal3(true)} className={'logo-icon-menu'}> <img src={logoIcon} alt="logoIcon"/> </div>
                    <Link to='/wind2' className='mobile-menu-link'>
                        <img src={windIcon} alt='windIcon' className='svg-icon' />
                    </Link>
                    <Link to='/cool' className='mobile-menu-link'>
                        <img src={coldIcon} alt='coldIcon' className='svg-icon' />
                    </Link>
                </div>
            </box>


        </div>
    )
}

export default AboutCompany
