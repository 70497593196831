import React, {useEffect, useState} from 'react'
import { motion } from 'framer-motion'
import useAppContext from '../../hooks/useAppContext'
import {
    leftSideVariants,
    rightSideVariants, rightSideVariants1,

} from '../../animationVariants'
import './contactsPage.css'
import './responsiveContactsPage.css'
import '../../../components/mobileMenu/mobileMenu.css'
import companyImg from '../../../assets/img/company-bg.jpg'
import {Link} from "react-router-dom";
import heatIcon from "../../../assets/svg/icon_heat.svg";
import waterIcon from "../../../assets/svg/icon_water.svg";
import logoIcon from "../../../assets/svg/icon_logo.svg";
import windIcon from "../../../assets/svg/icon_wind.svg";
import coldIcon from "../../../assets/svg/icon_cold.svg";
import bcoffice from "../../../assets/img/bcoffice.jpeg";



const Contacts = ({ menuColors }) => {
    const { setMenuColors } = useAppContext()
    useEffect(() => {
        setMenuColors(menuColors)
    }, [])

    const [hystmodal3, setHystmodal3]=useState(false)

    return (
        <div className='section-container'>

             <p
                className='fit-picture'
                style={{backgroundImage: `url(${bcoffice})` }}
            />

            <motion.div
                className='section-left-side-mob'
                initial='initial'
                animate='animate'
                variants={leftSideVariants}
                style={{
                    textAlign: "center",
                    margin: "auto"
                }}
            >
                <p className='right-header3'> Контакты </p>
            </motion.div>


            <div className="hystmodal-menu" aria-hidden="true" style={{display: hystmodal3===true?"block":"none"}}>
                <div className="hystmodal-menu__wrap">
                    <div className="hystmodal-menu__window" role="dialog" aria-modal="true">
                        <div className="header-menu__modal">
                            <p className="h1-menu_main_modal"> </p>
                            <button onClick={()=>setHystmodal3(false)} className="hystmodal-menu__close">X</button>
                        </div>
                        <div className="all-menu_text_modal">
                            <p> <Link to='/' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Главная </Link> </p>
                            <p> <Link to='/company' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> О компании </Link> </p>
                            <p> <Link to='/objects' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Реализованные объекты </Link> </p>
                            <p> <Link to='/compreal' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Комплексная реализация </Link> </p>
                            <p> <Link to='/service' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Сервисное обслуживание </Link> </p>
                            <p> <Link to='/engineering' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Проектирование объектов </Link> </p>
                            <p> <Link to='/proizvodstvo' style={{textDecoration: 'none', color: 'rgba(219, 219, 219, 1.81)'}}> Производство оборудования </Link> </p>
                            <p> <Link to='/contacts' style={{textDecoration: 'none', color: 'rgba(251, 169, 27, 1)'}}> Контакты </Link> </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className={'background-header'}
                 style={{
                     backgroundColor:'rgb(251, 169, 27)',
                     animation: `fade-in .10s ease-in-out`,
                     animationFillMode: 'backwards',

                 }}
            />


            <motion.div
                className='section-right-side'
                initial='initial'
                animate='animate'
                variants={rightSideVariants1}
            >

                <div className='content-contacts' >

                <p className='top-header' style={{color: '#fba91b'}}> КОНТАКТЫ </p>
                <p className='text-h2' style={{color: '#ffffff'}}> Адреса:</p>
                <p className='text-h2'> <a style={{color: '#fba91b'}}> Офис: &nbsp; </a> г.Москва, Шереметьевская улица, д.47, эт. 4, пом. 423, 127521</p>
                <p className='text-h2'><a style={{color: '#fba91b'}}> Производство: &nbsp; </a> Московская обл., Солнечногорский район, д.Шелепаново, с2</p>
                    <p className='text-h2' style={{color: '#ffffff'}}> Связаться:</p>
                    <p className='text-h2'><a style={{color: '#fba91b'}}> Телефон: &nbsp; </a> <a href="tel:+74993990012" style={{textDecoration: 'none', color: 'rgba(255, 255, 255, 0.81)'}}>+7 (499) 399-00-12</a> </p>
                <p className='text-h2'><a style={{color: '#fba91b'}}> Почта: &nbsp; </a> <a href="mailto:info@smseng.ru&cc=mail@smseng.ru.ru?body=Здравствуйтей, у меня вопрос." style={{textDecoration: 'none', color: 'rgba(255, 255, 255, 0.81)'}}>info@smseng.ru</a> </p>
                    <div className='div-maps'>
                        <iframe className='maps'
                                src="https://yandex.ru/map-widget/v1/?um=constructor%3A4bb29e6c412125667e730c66cc742ce0423b4432e3ec2133dd2e32a6d6876c92&amp;source=constructor"
                                frameBorder="0"></iframe>
                    </div>
                </div>
            </motion.div>

            <box className='footer'>
                <div className='mobile-menu'>
                    <Link to='/hot' className='mobile-menu-link'>
                        <img src={heatIcon} alt='heatIcon' className='svg-icon' />
                    </Link>
                    <Link to='/water' className='mobile-menu-link'>
                        <img src={waterIcon} alt='waterIcon' className='svg-icon' />
                    </Link>
                    <div onClick={()=>setHystmodal3(true)} className={'logo-icon-menu'}> <img src={logoIcon} alt="logoIcon"/> </div>
                    <Link to='/wind' className='mobile-menu-link'>
                        <img src={windIcon} alt='windIcon' className='svg-icon' />
                    </Link>
                    <Link to='/cold' className='mobile-menu-link'>
                        <img src={coldIcon} alt='coldIcon' className='svg-icon' />
                    </Link>
                </div>
            </box>


        </div>
    )
}

export default Contacts
